import { CommonTooltipComponent } from '@/components/common-tooltip/common-tooltip.component';
import {
  OverlayRef,
  Overlay,
  OverlayPositionBuilder,
  FlexibleConnectedPositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';

@Directive({ selector: '[tooltip]', standalone: true })
export class TooltipDirective {
  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
  ) {}

  @Input('tooltip') text = '';
  @Input('tooltipPosition') tooltipPosition:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right' = 'top';
  @Input('tooltipBg') tooltipBg: string = '#ebebeb';

  ngOnInit() {
    const positionStrategy = this.getPositionStrategy();
    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  private getPositionStrategy(): FlexibleConnectedPositionStrategy {
    let positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(
      this.elementRef,
    );

    switch (this.tooltipPosition) {
      case 'top':
        positionStrategy = positionStrategy.withPositions([
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetY: -10,
          },
        ]);
        break;
      case 'bottom':
        positionStrategy = positionStrategy.withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 10,
          },
        ]);
        break;
      case 'left':
        positionStrategy = positionStrategy.withPositions([
          {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center',
            offsetX: -10,
          },
        ]);
        break;
      case 'right':
        positionStrategy = positionStrategy.withPositions([
          {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
            offsetX: 10,
          },
        ]);
        break;
    }

    return positionStrategy;
  }

  @HostListener('mouseenter')
  show() {
    if (!this.overlayRef.hasAttached()) {
      const tooltipPortal = new ComponentPortal(CommonTooltipComponent);
      const tooltipRef: ComponentRef<CommonTooltipComponent> =
        this.overlayRef.attach(tooltipPortal);

      // Pass content to tooltip component instance
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.bg = this.tooltipBg;
    }
  }

  @HostListener('mouseleave', ['$event.target'])
  hide(target: HTMLElement) {
    this.overlayRef.detach();
  }
}
